
import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useEffect, useState, useRef } from "react";
import slugify from "slugify";
import ColorThief from "colorthief";

export const NodeLabelKeyHostname = "hostname";
export const NodeLabelKeyCheckpoints = "checkpoints";
export const NodeLabelKeyLoras = "loras";
export const NodeLabelKeyEmbeddings = "embeddings";
export const NodeLabelKeyUpscalers = "upscalers";
export const NodeLabelKeyControlnetModules = "controlnet_modules";
export const NodeLabelKeyControlnetModels = "controlnet_models";
export const NodeLabelKeyOllamaModels = "ollama_models";

export const SampleStatusRequestingAlloc = "requesting_alloc";
export const SampleStatusAllocProcessing = "alloc_processing";
export const SampleStatusAllocSuccess = "alloc_success";
export const SampleStatusAllocError = "alloc_error";

export const SampleSourceUser = "user";
export const SampleSourceAutoprompt = "autoprompt";
export const SampleSourceUserUpload = "user_upload";
export const SampleSourceSystem = "system";

export const SampleTypeTxt2Img = "Txt2Img";
export const SampleTypeBlob = "Blob";
export const SampleTypeImg2Img = "Img2Img";
export const SampleTypeChatCompletion = "ChatCompletion";
export const SampleTypeUpscale = "Upscale";
export const SampleTypeImg2Txt = "Img2Txt";

export const toastParams = {
    className: "fixed bottom-0 left-0 ml-4 mb-4 bg-white rounded-md shadow-md p-4",
    bodyClassName: "flex items-center",
    progressClassName: "w-full h-1 bg-gray-300 mt-2 rounded-full",
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
};

export const imgPreviewLoadQuality = 10;
export const imgPreviewLoadedQuality = 80;
export const imgChildAndParentPreviewQuality = 30;
export const imgMobilePreviewQuality = 30;
export const imgGridSampleQuality = 80;

export const tailwindConfig = {
    theme: {
        screens: {
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            "2xl": 1536,
        },
    },
};

export const sampleTypeHumanName = (s) => {
    switch (s) {
        case SampleTypeTxt2Img:
            return "Text → Image";
        case SampleTypeBlob:
            return "Blob";
        case SampleTypeImg2Img:
            return "Image → Image";
        case SampleTypeChatCompletion:
            return "Chat Completion";
        case SampleTypeUpscale:
            return "Upscale";
        case SampleTypeImg2Txt:
            return "Image → Text";
        default:
            return s;
    }
};

export let useAuth = useAuth0;
if (!document.URL.includes("app.tensorscale.ai")) {
    useAuth = () => {
        return {
            loginWithRedirect: () => { },
            logout: () => { },
            isAuthenticated: true,
            user: {
                name: "Test User",
                email: "",
            },
            isLoading: false,
        };
    };
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};

export const auth0JWTCookie = "auth0JWT";
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return "";
}

export function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

export const AuthContext = createContext(null);

export const MAX_SLUG_LEN = 100;

export function urlSlug(str, id) {
    return `${slugify(str.slice(0, MAX_SLUG_LEN), {
        remove: /[*+~.()'"!:@,\/]/g,
    })}-${id}`;
}

export function imgSrc(src, cfParams) {
    if (!document.URL.includes("app.tensorscale.ai")) {
        return `http://localhost:9998${src}`;
    }
    return `${cfParams},${src}`;
}

export async function apiFetch(path, options, auth0JWT) {
    try {
        if (auth0JWT) {
            if (!options.headers) options.headers = {};
            options.headers["Authorization"] = `Bearer ${auth0JWT}`;
        }
        if (!document.URL.includes("app.tensorscale.ai")) {
            path = `http://localhost:9998${path}`;
        }
        const result = await fetch(path, options);
        return handleAPIResult(result);
    } catch (error) {
        throw error;
    }
}

async function handleAPIResult(result) {
    const resultJSON = await result.json();
    if (result.ok) {
        return resultJSON;
    } else {
        throw new Error(resultJSON.error);
    }
}


export function downloadFile(url) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = url.split("/").pop();
    downloadLink.click();
}

export const shortCkpt = (fullCkpt) => {
    const split = fullCkpt.split(" ");
    if (split.length > 1) {
        return split.slice(0, -1).join(" ");
    }
    return fullCkpt;
};

export const useSuspendWindowListeners = (isOpen) => {
    const [listeners, setListeners] = useState([]);

    useEffect(() => {
        if (isOpen) {
            const currentListeners = [];

            for (const type in window._listeners) {
                const eventListeners = window._listeners[type];
                currentListeners.push({ type, eventListeners });
                eventListeners.forEach((listener) => {
                    window.removeEventListener(
                        type,
                        listener
                    );
                });
            }

            setListeners(currentListeners);
        } else {
            listeners.forEach(({ type, eventListeners }) => {
                eventListeners.forEach((listener) => {
                    window.addEventListener(type, listener);
                });
            });
            setListeners([]);
        }
    }, [isOpen]);
};

export const findBestMatchingModel = (module, controlnetModels) => {
    if (!controlnetModels) return undefined;

    const escapedModule = module.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(^|_)${escapedModule}(_|$)`);
    const matchedModels = controlnetModels.filter((model) =>
        regex.test(model)
    );
    const v11Model = matchedModels.find((model) => model.includes("v11"));
    return v11Model ? v11Model : matchedModels[0];
};

export function httpGETParams(auth0JWT) {
    return {
        method: "GET",
        headers: {
            Authorization: `Bearer ${auth0JWT}`,
        },
    };
}

export function isImageSample(sample) {
    return (
        sample.sampleType === SampleTypeImg2Img ||
        sample.sampleType === SampleTypeTxt2Img ||
        sample.sampleType === SampleTypeUpscale ||
        sample.sampleType === SampleTypeBlob
    );
}

export function useDocumentVisibility() {
    const [isVisible, setIsVisible] = useState(
        document.visibilityState === "visible"
    );

    const handleVisibilityChange = () => {
        setIsVisible(document.visibilityState === "visible");
    };

    useEffect(() => {
        document.addEventListener(
            "visibilitychange",
            handleVisibilityChange
        );
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, []);

    return isVisible;
}

export function useAutoRefresh() {
    const isVisible = useDocumentVisibility();
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [autoRefreshCounter, setAutoRefreshCounter] = useState(0);

    useInterval(() => {
        if (autoRefresh) {
            setAutoRefreshCounter((prev) => prev + 1);
        }
    }, 1000);

    useEffect(() => {
        setAutoRefresh(isVisible);
    }, [isVisible]);

    return [autoRefresh, autoRefreshCounter, setAutoRefresh];
}

// Function to fetch the most frequent color in the image
export async function fetchMostFrequentColor(imageUrl) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    // Use await to ensure the image loads completely
    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const colorThief = new ColorThief();
    const color = colorThief.getColor(img);
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

// Function to fetch the color palette of the image
export async function fetchColorPalette(imageUrl) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const colorThief = new ColorThief();
    const palette = colorThief.getPalette(img);
    return palette.map(
        (color) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    );
}