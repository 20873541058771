import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { lazy, StrictMode, useState, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext, auth0JWTCookie, getCookie } from "./consts";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const AdminPage = lazy(() => import('./AdminPage'));
const CollectionPage = lazy(() => import('./CollectionPage'));
const CollectionsPage = lazy(() => import('./CollectionsPage'));
const CreateAccount = lazy(() => import('./CreateAccount'));
const CustomizePage = lazy(() => import('./CustomizePage'));
const FeedPage = lazy(() => import('./FeedPage'));
const FileDropHandler = lazy(() => import('./FileDropHandler'));
const HomePage = lazy(() => import('./HomePage'));
const Login = lazy(() => import('./Login'));
const LogoutPage = lazy(() => import('./LogoutPage'));
const NodesAddPage = lazy(() => import('./NodesAddPage'));
const NodesPage = lazy(() => import('./NodesPage'));
const SamplePage = lazy(() => import('./SamplePage'));
const SamplesPage = lazy(() => import('./SamplesPage'));
const SearchPage = lazy(() => import('./SearchPage'));
const ThreadPage = lazy(() => import('./ThreadPage'));

const defaultFallback = <div>...</div>;
const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={defaultFallback}><HomePage /></Suspense>
    },
    {
        path: "/feed",
        element: <Suspense fallback={defaultFallback}><FeedPage /></Suspense>
    },
    {
        path: "/nodes",
        element: <Suspense fallback={defaultFallback}><NodesPage /></Suspense>
    },
    {
        path: "/nodes/add",
        element: <Suspense fallback={defaultFallback}><NodesAddPage /></Suspense>
    },
    {
        path: "/samples",
        element: <Suspense fallback={defaultFallback}><SamplesPage /></Suspense>
    },
    {
        path: "/samples/:sampleID",
        element: <Suspense fallback={defaultFallback}><SamplePage /></Suspense>
    },
    {
        path: "/threads/:threadID",
        element: <Suspense fallback={defaultFallback}><ThreadPage /></Suspense>
    },
    {
        path: "/customize",
        element: <Suspense fallback={defaultFallback}><CustomizePage /></Suspense>
    },
    {
        path: "/collections",
        element: <Suspense fallback={defaultFallback}><CollectionsPage /></Suspense>
    },
    {
        path: "/collections/:collectionIDSlug",
        element: <Suspense fallback={defaultFallback}><CollectionPage /></Suspense>
    },
    {
        path: "/auth0",
        element: <Suspense fallback={defaultFallback}><CreateAccount /></Suspense>
    },
    {
        path: "/logout",
        element: <Suspense fallback={defaultFallback}><LogoutPage /></Suspense>
    },
    {
        path: "/admin",
        element: <Suspense fallback={defaultFallback}><AdminPage /></Suspense>
    },
    {
        path: "/login",
        element: <Suspense fallback={defaultFallback}><Login /></Suspense>
    },
    {
        path: "/search",
        element: <Suspense fallback={defaultFallback}><SearchPage /></Suspense>
    },
]);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
    const [auth0JWT, setAuth0JWT] = useState(getCookie(auth0JWTCookie));

    const navAnchors = <div className="grid grid-cols-4 text-center">
        <a className="text-blue-500" href="/feed">Feed</a>
        <a className="text-blue-500" href="/samples">Sample</a>
        <a className="text-blue-500" href="/nodes">Nodes</a>
        <a className="text-blue-500" href="/collections">Collections</a>
        <a className="text-blue-500" href="/thread/new">New thread</a>
    </div>;

    let appChild = (<QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Suspense><FileDropHandler /></Suspense>
        <ToastContainer />
        <div className="sm:hidden sticky border-top border bottom-0 h-12 bg-gray-100 w-full">
            {navAnchors}
        </div>
    </QueryClientProvider>);

    if (document.URL.includes("app.tensorscale.ai")) {
        const auth0Provider = <Auth0Provider
            domain="dev-1l5c2ayq85ibfdza.us.auth0.com"
            clientId="slbeqSMFcTPhVaKvBI0vP8zGZodo83qp"
            redirectUri={window.location.origin + "/auth0"}
            audience="https://tensorscale.ai/api/v2"
        >
            {appChild}
        </Auth0Provider>;
        appChild = (auth0Provider);
    }

    const auth0ContextProvider = <AuthContext.Provider
        value={{
            auth0JWT,
            setAuth0JWT,
        }}
    >
        {appChild}
    </AuthContext.Provider>;

    return (
        <div>
            {auth0ContextProvider}
        </div>
    );
}

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
